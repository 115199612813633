import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { mobileThresholdPixels, colors } from './styledComponents';
import { Button } from './home/v2/styledComponents';
import { StepContainer as StepContainerV3, ComponentsContainer } from './home/v3/styledComponents';
import routesMap from '../Routes';
import withOrderContext from '../withOrderContext';
import ArrowButton from './order/LogoButton';
import OrderSummaryContainer from './order/OrderSummaryContainer';
import { pushToLayer } from '../services/googleTagManager';

const StepContainer1 = styled.div`
  display: block;
  background-color: ${colors.lightGrey};
`;

const StepContainer2 = styled(StepContainerV3)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: auto;
  }
`;

const Content = styled.div`
  display: block;
  margin: auto;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${(props) => !props.isAvailable && `
    border-color: ${({ theme }) => theme.colors.lightGrey3};
    color: ${({ theme }) => theme.colors.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${(props) => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

function addPiece() {
  pushToLayer({ event: 'Order Funnel - Extra Item Button Clicked' });
  navigate(routesMap.Step1.url);
}

const Step1Summary = ({ orderContext: { clothes, slots }, preFilledStep }) => (
  <StepContainer1>
    <Content>
      <OrderSummaryContainer
        clothes={clothes}
        selectedDates={slots}
        canEdit
        preFilledStep={preFilledStep}
      />

      <StepContainer2>
        <ComponentsContainer>
          <StyledButton onClick={addPiece} transparent isAvailable>
            + Ajouter une pièce
          </StyledButton>
          <StyledLink onClick={() => navigate(routesMap.Step2.url)}>
            <ArrowButton>OK, on passe au RDV !</ArrowButton>
          </StyledLink>
        </ComponentsContainer>
      </StepContainer2>
    </Content>
  </StepContainer1>
);

Step1Summary.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    slots: PropTypes.shape({}),
  }).isRequired,
  preFilledStep: PropTypes.shape({}),
};

Step1Summary.defaultProps = {
  preFilledStep: undefined,
};

export default withOrderContext(Step1Summary);
